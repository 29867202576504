module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/matt-swain/node_modules/gatsby-remark-images","id":"763eeb6f-3c4b-5fff-83ac-871b40d64a1e","name":"gatsby-remark-images","version":"3.1.44","pluginOptions":{"plugins":[],"maxWidth":1008,"linkImagesToOriginal":false,"disableBgImageOnAlpha":true,"backgroundColor":"transparent"},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"defaultLayouts":{"posts":"/matt-swain/src/components/post-layout.js","default":"/matt-swain/src/components/layout.js"},"gatsbyRemarkPlugins":["gatsby-remark-attr","/matt-swain/gatsby-remark-fig.js",{"resolve":"gatsby-remark-images","options":{"maxWidth":1008,"linkImagesToOriginal":true,"disableBgImageOnAlpha":true,"backgroundColor":"transparent"}},"gatsby-remark-copy-linked-files","gatsby-remark-smartypants","gatsby-remark-widows","gatsby-remark-katex"]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1008,"linkImagesToOriginal":false,"disableBgImageOnAlpha":true,"backgroundColor":"transparent"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Matt Swain","short_name":"Matt Swain","start_url":"/","background_color":"#fafafa","theme_color":"#fa6400","display":"minimal-ui","icon":"/matt-swain/static/logo.svg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-ackee-tracker/gatsby-browser.js'),
      options: {"plugins":[],"domainId":"9b395ebf-19c8-4a28-9e80-5965fee8625a","server":"https://oxo.mosxt.com","ignoreLocalhost":true,"detailed":true},
    }]
